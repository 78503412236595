import '~/public/assets/styles/non-critical-spoils.scss';
import './hydrator';
import './tracky';
import './ads';
import addNavListener from './nav';
import './spoils/right-rail-sticky';
import './hp';
import './recirc';
import './abStickyVideoTest';
import getProfile from './profile';
import './cmp-util';

addNavListener();
window.getProfile = getProfile;
